import styled from "styled-components"
import media from "styled-media-query"; 

export const WrapperTitleSolutions = styled.div`
  display: flex;
  justify-content: center;
`

export const TitleSolutions = styled.h3`
  margin-top: 20px;
  font-size: 40px;
  font-family: "Josefin Sans", sans-serif;
  color: ${props => props.theme.colors.subtitle};
  font-weight: 600;
  line-height: 20px;
  > hr {
    width: 280px;
    border: 0;
    height: 0;
    box-shadow: 0 0 5px 1px ${props => props.theme.colors.success};
    opacity: 0.7;
    margin-top: 1px;
  }
  ${media.lessThan("small")`
    text-align: center;
    font-size: 25px;
    > hr {
    width: 180px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
  }
  `}
  ${media.between("medium", "large")`
    font-size: 35px;
    > hr {
    width:220px;
    margin-left: 2px;
    margin-top: 1px;
    opacity: 0.7;
    }
    `}
`

export const Content = styled.section`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 260px;
  grid-template-areas:
    "contentLeft contentRight";
  ${media.lessThan("small")`
    display: block;
    margin-top: 30px;
  `}
  ${media.between("medium", "large")`
      grid-gap: 30px;
  `}
`

export const LeftCards = styled.div`
  grid-area: contentLeft;
  display: flex;
  justify-content: center;
`

export const RightCards = styled.div`
  grid-area: contentRight;
  display: flex;
  justify-content: center;
`

export const Card = styled.div`
  width: 553px;
  height: 260px;
  box-shadow: 0px 20px 20px #7682B72E;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 30px;
  border-left: solid 6px ${props => props.color || "palevioletred"};
  ${media.lessThan("small")`
    height: auto;
    margin-bottom: 30px;
  `}
`

export const Title = styled.h1`
  font-size: 25px;
  font-family: "Josefin Sans", sans-serif;
  line-height: 70px;
  color: #151D41;
  ${media.lessThan("small")`
    line-height: 40px;
    font-size: 20px;
  `}
  ${media.between("medium", "large")`
    line-height: 40px;
    font-size: 20px;
  `}
`

export const Description = styled.p`
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif;
  color: #9FA0A5;
  line-height: 30px;
  padding-right: 20px;
  ${media.lessThan("small")`
    padding-bottom: 40px;
    font-size: 16px;
  `}
  ${media.between("medium", "large")`
    font-size: 16px;
  `}
`


