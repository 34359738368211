import React from 'react'

import Layout from "../components/Layout"
import NavigationPages from "../components/NavigationPages"
import SEO from "../components/seo"
import EmployerContent from "../components/ForEmployers"

import { ThemeProvider } from 'styled-components'
import General from '../styles/theme'

const Employers = () => (
  <ThemeProvider theme={General}>
    <Layout>
    <SEO title="For Employers" />
    <NavigationPages />
    <EmployerContent/>
    </Layout>
  </ThemeProvider>
)

export default Employers