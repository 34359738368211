import React from "react"
import * as S from "./styled"
import { withTrans } from "../../i18n/withTrans"

const Employee = ({ t, i18n }) => (
  <>
{/*   <S.WrapperTitleSolutions>
    <S.TitleSolutions>
    {t("forEmployers.titleOne")}<hr />
    </S.TitleSolutions>
  </S.WrapperTitleSolutions> */}

  <S.Content>
    <S.LeftCards>
      <S.Card color="#7AD1A6">
        <S.Title>{t("forEmployers.titleTwo")}</S.Title>
        <S.Description>{t("forEmployers.titleThree")}</S.Description>
      </S.Card>
    </S.LeftCards>

    <S.RightCards>
      <S.Card color="#AA78ED">
        <S.Title>{t("forEmployers.titleFour")}</S.Title>
        <S.Description>{t("forEmployers.titleFive")}</S.Description>
      </S.Card>
    </S.RightCards>
  </S.Content>

  <S.Content>
    <S.LeftCards>
      <S.Card color="#ED7878">
        <S.Title>{t("forEmployers.titleSix")}</S.Title>
        <S.Description>{t("forEmployers.titleSeven")}</S.Description>
      </S.Card>
    </S.LeftCards>

    <S.RightCards>
      <S.Card color="#EDED78">
        <S.Title>{t("forEmployers.titleEight")}</S.Title>
        <S.Description>{t("forEmployers.titleNine")}</S.Description>
      </S.Card>
    </S.RightCards>
  </S.Content>

  <S.Content>
    <S.LeftCards>
      <S.Card color="#78C9ED">
        <S.Title>{t("forEmployers.titleTen")}</S.Title>
        <S.Description>{t("forEmployers.titleEleven")}</S.Description>
      </S.Card>
    </S.LeftCards>
    <S.RightCards>
    <S.Card color="#C0ED78">
      <S.Title>{t("forEmployers.titleTwelve")}</S.Title>
      <S.Description>{t("forEmployers.titleThirteen")}</S.Description>
    </S.Card>
  </S.RightCards>
  </S.Content>

  <S.Content>
    <S.LeftCards>
      <S.Card color="#ED78D7">
        <S.Title>{t("forEmployers.titleFourteen")}</S.Title>
        <S.Description>{t("forEmployers.titleFiveteen")}</S.Description>
      </S.Card>
    </S.LeftCards>
    <S.RightCards>
    <S.Card color="#4A45D6">
      <S.Title>{t("forEmployers.titleSixTeen")}</S.Title>
      <S.Description>{t("forEmployers.titleSevenTeen")}</S.Description>
    </S.Card>
  </S.RightCards>
  </S.Content>

  <S.Content>
    <S.LeftCards>
      <S.Card color="#F4B259">
        <S.Title>{t("forEmployers.titleEightTeen")}</S.Title>
        <S.Description>{t("forEmployers.titleNineTeen")}</S.Description>
      </S.Card>
    </S.LeftCards>
    <S.RightCards>
    <S.Card color="#DAA9F4">
      <S.Title>{t("forEmployers.titleTwenty")}</S.Title>
      <S.Description>{t("forEmployers.titleTwentyOne")}</S.Description>
    </S.Card>
  </S.RightCards>
  </S.Content>

</>
)

export default withTrans(Employee)